import React, { useState, useEffect, useRef } from 'react'
import { LikeIcon, LikedIcon, ShareIcon } from './assets'
import { _, Show } from '../common'
import { like, unLike, shared } from '../../api/insight'
import './InsightActions.css'

function Button(props) {
    const {
        className,
        active = false,
        icon,
        activeIcon,
        onClick = () => { },
        flip,
        grow
    } = props

    const ref = useRef()
    useEffect(() => {
        ref.current = active
    }, [active])

    const animate = active && ref.current === false
    const classes = _.classes("button", className, active && 'active', animate && 'animate', flip && 'flip', grow && 'grow')
    return (
        <div className={classes} onClick={onClick}>
            <Show show={!active}>
                <img alt="" src={icon} />
            </Show>
            <Show show={active}>
                <img alt="" src={activeIcon || icon} />
            </Show>
        </div>
    )
}

export default function InsightActions(props) {
    const insight = props.insight

    const [liked, setLiked] = useState(insight.liked)
    const [unLiked, setUnLiked] = useState(insight.unLiked)
    const clipboardInputRef = React.createRef()

    function toggleLike() {
        const value = !liked
        setLiked(value)
        setUnLiked(false)
        like(insight._id, value)
    }

    function toggleUnLike() {
        const value = !unLiked
        setUnLiked(value)
        setLiked(false)
        unLike(insight._id, value)
    }

    function share() {
        const link = insight.shareLink.url
        if (navigator.share) {
            const text = `${insight.renderedTitle}\n\n${insight.renderedDescription}\n\n`
            navigator.share({ url: link, text })
        } else {
            const clipboardInput = clipboardInputRef.current
            clipboardInput.value = link
            clipboardInput.select()
            document.execCommand('copy')
            alert('Link copied to clipboard')
        }
        shared(insight._id)
    }

    return (
        <div className="cotg-insights-insight-actions">
            <input className="clipboard" ref={clipboardInputRef} type="text" readOnly />
            <Button className="like" active={liked} icon={LikeIcon} activeIcon={LikedIcon} onClick={toggleLike} />
            <Button className="unlike" active={unLiked} icon={LikeIcon} activeIcon={LikedIcon} onClick={toggleUnLike} flip />
            {insight.shareLink && insight.shareLink.url &&
                <Button className="share" icon={ShareIcon} grow onClick={share} />
            }
        </div>
    )
}