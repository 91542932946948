import React, { Component } from 'react'
import { business, user } from '../api'
import withUserSession from './WithUserSession'
import { _, Menu } from './common'
import liveIcon from './live.svg'
import logoutIcon from './logout.svg'
import './AppBar.css'

class AppBar extends Component {
  state = {
    businesses: null,
    businessesMenuOpened: false,
    logoLoaded: false,
    logoIsWide: null,
  }

  async componentDidMount() {
    const _business = this.props.userSession.business
    let businesses = await business.list()
    businesses = businesses.filter(({ id }) => id !== _business.id)
    this.setState({ businesses })
  }

  handleLogoLoad = ({ target: img }) =>
    this.setState({
      logoLoaded: true,
      logoIsWide: img.offsetWidth / img.offsetHeight > 2
    })


  openBusinessesMenu = () => this.setState({ businessesMenuOpened: true })

  closeBusinessesMenu = () => this.setState({ businessesMenuOpened: false })

  switchBusiness = businessId => () => business.setCurrent(businessId)

  logout = () => user.logout()

  emitLiveChange = () => this.props.onLiveChange && this.props.onLiveChange(!Boolean(this.props.live))

  render() {
    const business = this.props.userSession.business
    const { businesses } = this.state
    const hasMultipleBusinesses = businesses && businesses.length > 0
    return (
      <div className="cotg-app-bar" >
        <div className={_.classes('business', hasMultipleBusinesses && 'multiple')} onClick={this.openBusinessesMenu}>
          {business.logoURL &&
            <div className="logo">
              <img onLoad={this.handleLogoLoad} src={business.logoURL} alt="Logo" />
            </div>
          }
          {(!business.logoURL || (this.state.logoLoaded && !this.state.logoIsWide)) &&
            <div className="name">{business.name}</div>
          }
        </div>
        <div className="controls">
          <div className="live control" disabled={!Boolean(this.props.live)} onClick={this.emitLiveChange}>
            <img src={liveIcon} alt="Live" />
            live
          </div>
          <div className="divider" />
          <div className="logout control" onClick={this.logout}>
            <img src={logoutIcon} alt="Sign Out" />
          </div>
        </div>
        {hasMultipleBusinesses > 0 &&
          <Menu className="cotg-app-bar-businesses-menu"
            open={this.state.businessesMenuOpened}
            onClose={this.closeBusinessesMenu}>
            {businesses.map(({ id, name }) =>
              <Menu.MenuItem key={id} onClick={this.switchBusiness(id)}>{name}</Menu.MenuItem>
            )}
          </Menu>
        }
      </div>
    )
  }
}

export default withUserSession(AppBar)