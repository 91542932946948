import React, { Component } from 'react'
import UpArrow from './arrow-up.svg'
import DownArrow from './arrow-down.svg'
import CountUp from 'react-countup'
import { _, Transition } from '../common'
import './AmountKPI.css'

export default class AmountKPI extends Component {
    state = {
        data: this.props.data,
        prevData: null
    }

    componentDidUpdate() {
        if (!_.isEqual(this.state.data, this.props.data))
            this.setState({
                data: this.props.data,
                prevData: this.state.data
            })
    }


    render() {
        const { title, valuePrefix = '', span, icon } = this.props
        let { data, prevData } = this.state
        const normalizeData = data => (data && typeof data !== 'object' && { current: data }) || data || {}
        data = normalizeData(data)
        prevData = normalizeData(prevData)

        const value = parseInt(data.current)
        const change = parseInt(data.change)
        const prevValue = parseInt(prevData.current)
        const prevChange = parseInt(prevData.change)
        return (
            <Transition
                className={_.classes('cotg-dashboard-amount-kpi cotg-dashboard-kpi', span && 'span', span && 'span-column')}
                show={data.current != null} fade scale>
                <div className="title">
                    {title}
                </div>
                <div className="data">
                    <div className="value">
                        {(span || value < 10000) &&
                            <CountUp start={prevValue || value} end={value} duration={3} prefix={valuePrefix} separator="," />
                        }
                        {!span && value >= 10000 &&
                            <CountUp start={Math.max(10000, prevValue || value) / 1000} end={value / 1000} duration={3} prefix={valuePrefix} suffix="k" separator="," decimals={1} />
                        }
                    </div>
                    <div className={_.classes('trend', change > 0 && 'up', change < 0 && 'down')}>
                        <div>
                            {change > 0 && <img src={UpArrow} alt="Increase" />}
                            {change < 0 && <img src={DownArrow} alt="Decrease" />}
                            {!isNaN(change) && change !== 0 &&
                                <CountUp start={prevChange || change} end={change} duration={3} suffix="%" separator="," />
                            }
                        </div>
                    </div>
                </div>
                {icon &&
                    <img className="icon" src={icon} alt="Icon" />
                }
            </Transition>
        )
    }
}