import React from 'react'
import ComingSoonImage from './assets/coming-soon.svg'
import './InsightsComingSoon.css'

export default function InsightsComingSoon() {
    return (
        <div className="cotg-insights-coming-soon">
            <img src={ComingSoonImage} alt="Coming Soon" />
            <div className="subtitle">Your Business Insights</div>
        </div>
    )
}