import React, { Component } from 'react'
import ComodashView from './ComodashView'

export default class WithComodash extends Component {
    render() {
        return (
            <React.Fragment>
                <ComodashView />
                {this.props.children}
            </React.Fragment>
        )
    }
}