import React, { Component } from "react"
import { uuid } from './uuid'
import './ComodashView.css'

const ID = 'cotg-comodashview'
export default class ComodashView extends Component {
    state = {
        components: {}
    }

    componentDidMount() {
        document.getElementById(ID).addEventListener('add', this.add, false)
        document.getElementById(ID).addEventListener('remove', this.remove, false)
    }

    componentWillUnmount() {
        document.getElementById(ID).removeEventListener('add', this.add, false)
        document.getElementById(ID).removeEventListener('remove', this.remove, false)
    }

    add = ({ detail: { id, component } }) => {
        this.setState(({ components }) => {
            components[id] = component
            return { components }
        })
    }

    remove = ({ detail: { id } }) => {
        this.setState(({ components }) => {
            delete components[id]
            return { components }
        })
    }

    render() {
        const { components } = this.state
        return (
            <div id={ID}>
                {Object.keys(components).map(key => React.cloneElement(
                    components[key],
                    { key: key }
                ))}
            </div>
        )
    }
}

export const add = (component, id = uuid()) => {
    const addEvent = new CustomEvent('add', {
        detail: {
            id, component
        }
    })
    document.getElementById(ID).dispatchEvent(addEvent)
    return id
}

export const remove = (id) => {
    const removeEvent = new CustomEvent('remove', {
        detail: {
            id
        }
    })
    document.getElementById(ID).dispatchEvent(removeEvent)
}