import backend from './backend'

export async function list() {
    const { data: insights } = await backend.get('/api/insights?limit=10&since=P7D')
    return insights
}

export function seen(id) {
    backend.post(`/api/insights/${id}/seen`)
}

export function like(id, value = true) {
    backend.post(`/api/insights/${id}/like`, { value })
}

export function unLike(id, value = true) {
    backend.post(`/api/insights/${id}/unlike`, { value })
}

export function shared(id) {
    backend.post(`/api/insights/${id}/shared`)
}

export default {
    seen,
    list,
    like,
    unLike,
    shared,
}