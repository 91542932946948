import React from 'react'
import { TypeBenefitsIcon, TypePointsIcon, TypeSalesIcon, TypeEngagementIcon } from './assets'
import './InsightHeader.css'
const DefaultIcon = TypeSalesIcon

export default function InsightHeader({ type }) {
    let icon;
    type = type.match(/(.+)\./)[1]
    let header = type
    switch (type) {
        case "points":
            icon = TypePointsIcon
            break;
        case "sales":
            icon = TypeSalesIcon
            break;
        case "benefits":
            icon = TypeBenefitsIcon
            break;
        case "engagement":
            icon = TypeEngagementIcon
            break;
        default:
            icon = DefaultIcon
            break;
    }

    return (
        <div className="cotg-insights-insight-header">
            <img alt="Icon" src={icon} />
            <div>{header}</div>
        </div>
    )
}