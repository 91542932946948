import React from 'react'
import ReactLoading from 'react-loading'
import lodash from 'lodash'
import uuid from './uuid'
import { add as addToView, remove as removeFromView } from './ComodashView'
import './comodash.css'

let loadingCounter = 0
const startLoading = () => {
    loadingCounter++
    if (loadingCounter === 1)
        addToView(<div className="comodash_loading"><ReactLoading type="cylon" color="#3fb3e2"/></div>, 'comodash_loading')
    return true
}

const stopLoading = () => {
    loadingCounter--
    if (loadingCounter === 0)
        removeFromView('comodash_loading')
    return false
}

const loadingWhile = async (fnc) => {
    startLoading()
    await fnc()
    stopLoading()
}

function classes() {
    return Array.prototype.slice.call(arguments).filter(arg => arg !== false).join(' ')
}

const kNumber = (number, approximate = false) => {
    const k = number > 999
    let hasReminder
    if (k) {
        hasReminder = number % 1000 > 0
        const decimal = number > 9999 ? 0 : 1
        number = number / 1000
        number = parseFloat(number).toFixed(decimal)
    }

    number = Number(number).toLocaleString()
    if (k) {
        number = `${number}k`
        if (approximate && hasReminder)
            number = `~${number}`
    }
    return number
}

export default Object.assign({}, lodash, uuid, {
    startLoading,
    stopLoading,
    loadingWhile,
    classes,
    kNumber
})