import React, { Component } from 'react'
import { ResponsivePie as PieChart } from '@nivo/pie'
import { _, Transition } from '../common'
import './GroupByKPI.css'

export default class GroupByKPI extends Component {
    componentDidUpdate() {
        document.querySelectorAll('.cotg-dashboard-groupby-kpi linearGradient[y2="1"]').forEach(linearGradient => {
            linearGradient.setAttribute('y2', 0)
            linearGradient.setAttribute('x2', 1)
        })
    }

    render() {
        const { title, groupBy, minSize = 1, valuePrefix = '' } = this.props
        let { data = [] } = this.props
        const ready = data.length >= minSize
        data = data.map(i => ({ id: i[groupBy], value: i.value }))

        return (
            <Transition className="cotg-dashboard-groupby-kpi cotg-dashboard-kpi span-column" show={ready}>
                <div className="title">
                    {title}
                </div>
                <div className="data">
                    <PieChart
                        data={data}
                        keys={['value']}
                        indexBy={groupBy}
                        margin={{ top: 50, right: 50, bottom: 50, left: 50 }}
                        innerRadius={0.70}
                        padAngle={1.5}
                        cornerRadius={2}
                        isInteractive={false}
                        radialLabel={({ id, value }) => <React.Fragment><tspan x="0" y="7">{`${valuePrefix}${_.kNumber(value)}`}</tspan><tspan x="0" y="-7">{id}</tspan></React.Fragment>}
                        radialLabelsTextColor="white"
                        radialLabelsLinkColor="white"
                        radialLabelsLinkOffset={5}
                        radialLabelsLinkStrokeWidth={1}
                        radialLabelsLinkDiagonalLength={5}
                        radialLabelsLinkHorizontalLength={10}
                        radialLabelsSkipAngle={15}
                        sliceLabel={() => ''}
                        startAngle={-30}
                        colors={["#02b9e5", "#651fff", "#3d5afe", "#2979ff", "#00b0ff"]}
                        borderColor={{ "from": "color", "modifiers": [["opacity", 0.3]] }}
                        borderWidth={1}
                        defs={[
                            {
                                id: 'gradientA',
                                type: 'linearGradient',
                                x2: 1,
                                y2: 0,
                                colors: [
                                    { offset: 0, color: '#02b9e5' },
                                    { offset: 50, color: '#216881' },
                                    { offset: 100, color: '#244a5b' },
                                ],
                            },
                            {
                                id: 'gradientB',
                                type: 'linearGradient',
                                colors: [
                                    { offset: 0, color: '#651fff' },
                                    { offset: 50, color: '#462a98' },
                                    { offset: 100, color: '#392f6e' },
                                ],
                            },
                            {
                                id: 'gradientC',
                                type: 'linearGradient',
                                colors: [
                                    { offset: 0, color: '#3d5afe' },
                                    { offset: 50, color: '#34459b' },
                                    { offset: 100, color: '#2f3a61' },
                                ],
                            },
                            {
                                id: 'gradientD',
                                type: 'linearGradient',
                                colors: [
                                    { offset: 0, color: '#2979ff' },
                                    { offset: 50, color: '#294d89' },
                                    { offset: 100, color: '#2a3d5f' },
                                ],
                            },
                            {
                                id: 'gradientE',
                                type: 'linearGradient',
                                colors: [
                                    { offset: 0, color: '#00b0ff' },
                                    { offset: 50, color: '#23658d' },
                                    { offset: 100, color: '#22455d' },
                                ],
                            }
                        ]}
                        fill={[
                            {
                                id: 'gradientA',
                                match: ({ index }) => index === 0
                            },
                            {
                                id: 'gradientB',
                                match: ({ index }) => index === 1
                            },
                            {
                                id: 'gradientC',
                                match: ({ index }) => index === 2
                            },
                            {
                                id: 'gradientD',
                                match: ({ index }) => index === 3
                            },
                            {
                                id: 'gradientE',
                                match: ({ index }) => index === 4
                            }
                        ]}
                    />
                </div>
            </Transition>
        )
    }
}