import React from 'react'
import { _ } from '.'
import './Select.css'

function Option({ children: text, selected = false, onClick }) {
    return (
        <div className={_.classes('option', selected && 'selected')} onClick={onClick}>
            {text}
        </div>
    )
}


function Select({ children: options, value, disabled = false, onChange = () => { } }) {
    return (
        <div className="cotg-select" disabled={disabled}>
            {React.Children.map(options, option =>
                React.cloneElement(option, {
                    selected: option.props.value === value,
                    onClick: () => !disabled && onChange(option.props.value)
                }))}
        </div>
    )
}
Select.Option = Option
export default Select