import React, { Component } from 'react'
import './Menu.css'
import { Transition, _ } from '.'
import arrowImage from './menu-arrow.svg'

class MenuItem extends Component {
    render() {
        return (
            <div className="item" onClick={this.props.onClick}>{this.props.children}</div>
        )
    }
}

class Menu extends Component {
    state = {
        searchPattern: null
    }

    updateSearchPattern = event => {
        let searchPattern = event.target.value
        searchPattern = searchPattern && searchPattern.length > 0 ? `.*${searchPattern}.*` : '.*'
        searchPattern = new RegExp(searchPattern, 'i')
        this.setState({ searchPattern })
    }

    close = () => {
        this.setState({ searchPattern: null })
        this.props.onClose && this.props.onClose()
    }

    render() {
        const open = this.props.open
        const searchPattern = this.state.searchPattern || /.*/

        let items = React.Children.toArray(this.props.children)
        const showSearch = items.length >= 10
        items = items.filter(menuItem => searchPattern.test(`${menuItem.key}${menuItem.props.children}`))
        let height
        let bottom
        if (showSearch) {
            height = Math.min(items.length * 48, 48 * 3.5)
            bottom = height * -1 - 48
        } else {
            height = Math.min(items.length * 48, 48 * 4.5)
            bottom = height * -1
        }

        return (
            <React.Fragment>
                <Transition className={_.classes('cotg-menu', showSearch && 'search')} show={open} style={{ bottom }} fade scale>
                    {open &&
                        <React.Fragment>
                            {showSearch &&
                                <div className="search">
                                    <input
                                        ref={input => input && setTimeout(() => input && input.focus(), 500)}
                                        placeholder="Search"
                                        onChange={this.updateSearchPattern} />
                                    <img src={arrowImage} alt='Close' onClick={this.close} />
                                </div>
                            }
                            <div className="items" style={{ height }}>
                                {items}
                            </div>
                        </React.Fragment>
                    }
                </Transition>
                {open && <div className="cotg-menu-overlay" onClick={this.close} />}

            </React.Fragment>
        )
    }
}
Menu.MenuItem = MenuItem
export default Menu