import React from 'react'
import './InsightBody.css'

export default function InsightBody({ insight }) {
    let { renderedHTMLTitle, renderedHTMLDescription } = insight
    return (
        <div className="cotg-insights-insight-body">
            <div className="title" dangerouslySetInnerHTML={{ __html: renderedHTMLTitle }} />
            <div className="description" dangerouslySetInnerHTML={{ __html: renderedHTMLDescription }} />
        </div>
    )
}