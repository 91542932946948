import axios from 'axios'
import backend from './backend'

async function login(email, password, totp) {
    try {
        sessionStorage.clear()
        localStorage.clear()

        const response = await axios.post('/api/users/login', { email, password, totp: totp ? totp : undefined })

        if(response.data.get2FA)
            return response.data;

        window.location.href = '/'
        return true
    } catch (err) {
        return err.response.data
    }
}

async function logout() {
    await backend.post('/api/users/logout')
    sessionStorage.clear()
    localStorage.clear()
    window.location.href = '/'
}

async function me() {
    try {
        const { data: user } = await axios.get('/api/users/me')
        return user
    } catch (err) {
        return false
    }
}

async function resetPassword(email) {
    await axios.post('/api/users/password/reset', { email })
}

export default {
    login,
    logout,
    me,
    resetPassword
}
