import backend from './backend'

async function getTotalMembers() {
    const { data: kpi } = await backend.get(`/api/businesses/kpis/members_total`)
    return parseInt(kpi)
}

async function getBuyingMembers(period) {
    const { data: kpi } = await backend.get(`/api/businesses/kpis/members_buying?period=${period}`)
    return kpi
}

async function getNewMembers(period) {
    const { data: kpi } = await backend.get(`/api/businesses/kpis/members_new?period=${period}`)
    return kpi
}

async function getSales(period) {
    const { data: kpi } = await backend.get(`/api/businesses/kpis/sales?period=${period}`)
    return kpi
}

async function getSalesByLocation(period) {
    const { data: kpi } = await backend.get(`/api/businesses/kpis/sales_by_location?period=${period}`)
    return kpi
}

async function getSalesOverTime(period) {
    const { data: kpi } = await backend.get(`/api/businesses/kpis/sales_over_time?period=${period}`)
    return kpi
}

async function getVisits(period) {
    const { data: kpi } = await backend.get(`/api/businesses/kpis/visits?period=${period}`)
    return kpi
}

async function getVisitAvgSpend(period) {
    const { data: kpi } = await backend.get(`/api/businesses/kpis/visit_avg_spend?period=${period}`)
    return kpi
}

async function getAppOpens(period) {
    const { data: kpi } = await backend.get(`/api/businesses/kpis/app_opens?period=${period}`)
    return kpi
}

async function getRedeems(period) {
    const { data: kpi } = await backend.get(`/api/businesses/kpis/redeems?period=${period}`)
    return kpi
}

export default {
    getTotalMembers,
    getBuyingMembers,
    getNewMembers,
    getSales,
    getSalesByLocation,
    getSalesOverTime,
    getVisits,
    getVisitAvgSpend,
    getAppOpens,
    getRedeems
}