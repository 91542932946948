import React, { Component } from 'react'
import { ResponsiveLine as LineChart } from '@nivo/line'
import moment from 'moment-timezone'
import { Transition } from '../common'
import withUserSession from '../WithUserSession'
import './OverTimeKPI.css'

const HOUR_AXIS = timeZone => ({
    format: value => moment(value).tz(timeZone).format('hA'),
    tickValues: new Array(48).fill(moment().startOf('day').subtract(1, 'days')).map((d, index) => moment(d).add(index, 'hours').toDate()),
    tickFontSize: dpLength => dpLength >= 16 ? 6 : (dpLength >= 12 ? 7 : 8)
})

const DAY_AXIS = (timeZone, firstDay) => ({
    format: value => moment(value).tz(timeZone).format('dddd'),
    tickValues: new Array(10).fill(moment(firstDay).subtract(2, 'days')).map((d, index) => moment(d).add(index, 'days').toDate()),
    tickFontSize: dpLength => dpLength >= 5 ? 8 : 9
})

const DATE_AXIS = (timeZone, firstDay) => ({
    format: value => moment(value).tz(timeZone).format('D'),
    tickValues: new Array(35).fill(moment(firstDay).subtract(2, 'days')).map((d, index) => moment(d).add(index, 'days').toDate()),
    tickFontSize: dpLength => dpLength >= 28 ? 7 : (dpLength >= 20 ? 8 : 9)
})

const ValuesLayer = (() => {
    const POINT_PADDING = 15
    return ((chartHeight, chartMargin, valuePrefix) => ({ points, xScale, yScale }) => {
        const maxX = Math.max(...points.map(({ x }) => x))
        points = points.filter(({ x }) => x === maxX)
        const maxY = Math.min(...points.map(({ y }) => y))

        const x = maxX - 100
        const y = maxY > 40 ? maxY - (16 + 10 * points.length + POINT_PADDING) : maxY + POINT_PADDING
        const height = chartHeight - chartMargin.top - chartMargin.bottom - y
        return (
            <foreignObject x={x} y={y} width="200" height={height}>
                <div className="values-layer">
                    {points.map(point =>
                        <div className="row" key={point.id}>
                            <div className="circle" style={{ borderColor: point.serieColor }} />
                            <div className="value">
                                {valuePrefix}{Number(parseInt(point.data.y)).toLocaleString()}
                            </div>
                        </div>
                    )}
                </div>
            </foreignObject>
        )
    })
})()

class OverTimeKPI extends Component {
    render() {
        const timeZone = this.props.userSession.business.timeZone
        const { data = {}, period, minSize = 2, keys = ['value'], displayKeys, valuePrefix = '' } = this.props
        let dataPoints = data.dataPoints || []
        const dataPointsLength = dataPoints.length
        const ready = dataPointsLength >= minSize
        dataPoints = keys.map((key, index) => ({
            id: displayKeys && displayKeys[index] ? displayKeys[index] : key,
            data: dataPoints
                .filter(dp => dp[key] != null)
                .map(dp => ({ x: new Date(dp.date), y: dp[key] }))
        }))
        const values = dataPoints.flatMap(({ data }) => data)
        const xValues = values.flatMap(({ x }) => x)
        const minX = new Date(Math.min(...xValues))
        const maxX = new Date(Math.max(...xValues))
        const maxY = Math.max(...values.flatMap(({ y }) => y))
        const bottomAxis = data.precision === 'hour' ? HOUR_AXIS(timeZone) : (period === 'this_week' ? DAY_AXIS(timeZone, minX) : DATE_AXIS(timeZone, minX))
        const margin = { top: 10, bottom: 40, left: 0, right: 0 }
        const height = 200

        return (
            <Transition className="cotg-dashboard-overtime-kpi cotg-dashboard-kpi span-column span-row strech" show={ready} fade>
                {ready &&
                    <LineChart
                        layers={['grid', 'axes', 'areas', 'lines', 'points', ValuesLayer(height, margin, valuePrefix)]}
                        colors={["#02b9e5", "#651fff"]}
                        height={height}
                        data={dataPoints}
                        curve="cardinal"
                        enableArea={true}
                        margin={margin}
                        lineWidth={3}
                        pointSize={7}
                        isInteractive={false}
                        enableGridX={true}
                        enableGridY={false}
                        xScale={{
                            type: 'time',
                            format: 'native',
                            precision: data.precision,
                            // min: minX,
                            min: new Date(minX.getTime() - (maxX.getTime() - minX.getTime()) * 0.05),
                            max: new Date((maxX.getTime() - minX.getTime()) * 1.20 + minX.getTime())
                        }}
                        yScale={{
                            type: 'linear',
                            min: 0,
                            max: maxY * 1.25,
                            stacked: false
                        }}
                        axisBottom={bottomAxis}
                        gridXValues={bottomAxis.tickValues}
                        pointColor="#c4f3fe"
                        pointBorderWidth={1}
                        pointBorderColor={{ from: 'serieColor' }}
                        theme={{
                            axis: {
                                ticks: {
                                    text: {
                                        fill: "#b7bdd3",
                                        fontSize: bottomAxis.tickFontSize(dataPointsLength)
                                    }
                                }
                            },
                            grid: {
                                line: {
                                    stroke: "#333b4a"
                                }
                            }
                        }}
                    />
                }
                <div className="legends">
                    {displayKeys.map((key, index) =>
                        <div key={key} className={`legend i${index}`}>
                            <div className="bullet" />
                            <div className="text">{key}</div>
                        </div>
                    )}
                </div>
            </Transition>
        )
    }
}

export default withUserSession(OverTimeKPI)