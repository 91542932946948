import axios from 'axios'

async function current() {
    var urlParams = new URLSearchParams(window.location.search);
    let id = urlParams.get("bId")
    if (!id) {
        id = (await list()).pop().id
        urlParams.set("bId", id)
        window.history.replaceState(null, '', `${window.location.pathname}?${urlParams.toString()}`)
    }
    let business = await get(id)
    if (!business)
        business = { name: id, fullAccess: false }
    return business
}

async function setCurrent(id) {
    window.location.href = `/?bId=${id}`
}

async function list() {
    const { data: businesses } = await axios.get('/api/businesses')
    return businesses
}

async function get(id) {
    try {
        const { data: business } = await axios.get(`/api/businesses/${id}`)
        return business
    } catch (e) {
        if (e.response.status === 404)
            return null
        throw e
    }
}

export default {
    current,
    setCurrent,
    list,
    get
}