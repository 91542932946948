import React, { forwardRef } from 'react'
import { _ } from '../common'
import moment from 'moment'
import InsightHeader from './InsightHeader'
import InsightBody from './InsightBody'
import InsightActions from './InsightActions'
import { NewIcon } from './assets'
import './Insight.css'

function Insight(props, ref) {
    const { insight, highlight = false } = props
    const { type, createdAt, seen } = insight

    return (
        <div className={_.classes('cotg-insights-insight', (highlight || !seen) && 'highlight')} ref={ref}>
            <div className="card">
                <div className="notification">
                    <div className="created">{moment(createdAt).format('YYYY-MM-DD')}</div>
                    {!seen && <img className="new" src={NewIcon} alt="new" />}
                </div>
                <InsightHeader type={type} />
                <InsightBody insight={insight} />
                <InsightActions insight={insight} />
            </div>
        </div>
    )
}

export default forwardRef(Insight)