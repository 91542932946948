import React from 'react'
import { _ } from '.'
import './Transition.css'

export default function Transition(props) {
    const { children, show = true, className = '', style = {}, fade = false, scale = false } = props
    const classes = _.classes('cotg-transition', show && 'show', fade && 'fade', scale && 'scale', className)
    return (
        <div className={classes} style={style}>
            <React.Fragment>
                {children}
            </React.Fragment>
        </div>
    )
}