import React from 'react'
import ReactDOM from 'react-dom'
import App from './app/App'
import { Provider as WithDeviceInfo } from './app/WithDeviceInfo'
import { Provider as WithUserSession } from './app/WithUserSession'
import { WithComodash } from './app/common'

const app = (
    <WithDeviceInfo>
        <WithComodash>
            <WithUserSession>
                <App />
            </WithUserSession>
        </WithComodash>
    </WithDeviceInfo>
)
ReactDOM.render(app, document.getElementById('root'))