import React from 'react'
import { withRouter, matchPath } from "react-router";
import { _ } from '.'

import './Navigation.css'

function Tab({ children: text, selected = false, badge, onClick }) {
    return (
        <div className={_.classes('tab', selected && 'selected')} onClick={onClick}>
            <div className="text">
                <span>
                    {text}
                    {badge > 0 && <div className="badge">{badge < 10 ? badge : "9+"}</div>}
                </span>

            </div>
            <div className="indicator" />
        </div>
    )
}


function Navigation({ history, children: tabs }) {
    return (
        <div className="cotg-navigation">
            {React.Children.map(tabs, tab =>
                React.cloneElement(tab, {
                    selected: matchPath(history.location.pathname, { path: tab.props.path }),
                    onClick: () => history.replace({ ...history.location, pathname: tab.props.path })
                }))}
        </div>
    )
}
Navigation.Tab = Tab
export default withRouter(Navigation)