import React, { Component } from 'react'
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import AppBar from './AppBar'
import { Navigation } from './common'
import UserLogin from './user/Login'
import LackAccess from './user/LackAccess'
import Dashboard from './dashboard/Dashboard'
import Insights from './insights/Insights'
import withUserSession from './WithUserSession'
import withDeviceInfo from './WithDeviceInfo'
import { insight } from '../api'
import './App.css'

class App extends Component {
  state = {
    profileMenuAnchor: null,
    live: JSON.parse(sessionStorage.getItem('como_otg_live') || true),
    insights: null
  }
  liveTimer = null
  contentScroll = React.createRef()

  componentDidMount() {
    if (!this.userHasAccess())
      return
    this.updateInsights()
    this.liveTimer = setInterval(() => this.state.live && this.updateInsights(), 10000)
    window.scrollTo = (x, y) => this.contentScroll.current.scrollTo(0, y - this.contentScroll.current.offsetTop)
  }

  componentWillMount() {
    clearInterval(this.liveTimer)
    delete window.scrollTo
  }

  handleLiveChange = live => {
    this.setState({ live })
    sessionStorage.setItem("como_otg_live", live)
  }

  updateInsights = async () => {
    const insights = await insight.list()
    this.setState({ insights })
  }

  insightsBadgeCount = () => (this.state.insights || []).filter(({ seen }) => !seen).length

  userLoggedIn = () => this.props.userSession.user != null

  userHasAccess = () => this.userLoggedIn() && this.props.userSession.business.fullAccess

  render() {
    const { live } = this.state

    return (
      <div className={`cotg-app ${[...this.props.deviceInfo.labels].join(' ')}`}>
        <Router>
          <Switch>
            <Route exact path="/login" component={UserLogin} />
            {!this.userLoggedIn() && <Redirect to="/login" />}
            <Route render={() =>
              <React.Fragment>
                <AppBar live={live} onLiveChange={this.handleLiveChange} />
                {!this.userHasAccess() &&
                  <LackAccess />
                }
                {this.userHasAccess() &&
                  <React.Fragment>
                    <Navigation>
                      <Navigation.Tab path="/dashboard">Dashboard</Navigation.Tab>
                      <Navigation.Tab path="/insights" badge={this.insightsBadgeCount()}>Insights</Navigation.Tab>
                    </Navigation>
                    <div className="content-scroll" ref={this.contentScroll}>
                      <div className="content">
                        <Switch>
                          <Route path="/dashboard" render={routeProps => <Dashboard {...routeProps} live={live} />} />
                          <Route path="/insights" render={routeProps => <Insights {...routeProps} insights={this.state.insights} onUnmount={this.updateInsights} />} />
                          <Route render={({ location }) => <Redirect to={{ ...location, pathname: '/dashboard' }} />} />} />
                        </Switch>
                      </div>
                    </div>
                  </React.Fragment>
                }
              </React.Fragment>
            } />
          </Switch>
        </Router>
      </div>
    )
  }
}

export default withDeviceInfo(withUserSession(App))