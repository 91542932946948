import React, { Component } from 'react'
import { matchPath } from "react-router";
import { insight } from '../../api'
import Insight from './Insight'
import InsightsComingSoon from './InsightsComingSoon'
import './Insights.css'

export default class Insights extends Component {
    highlightedInsightId = (matchPath(this.props.history.location.pathname, { path: "/insights/:id" }) || { params: {} }).params.id
    highlightedInsightRef = React.createRef()
    highlightedInsightScrolled = false

    componentDidMount() {
        window.addEventListener('beforeunload', this.emitSeen);
    }

    async componentWillUnmount() {
        await this.emitSeen()
        this.props.onUnmount && this.props.onUnmount()
        window.removeEventListener('beforeunload', this.emitSeen)
    }

    componentDidUpdate() {
        if (!this.highlightedInsightScrolled && this.highlightedInsightRef.current) {
            this.highlightedInsightScrolled = true
            window.scrollTo(0, this.highlightedInsightRef.current.offsetTop - 20)
        }
    }

    emitSeen = async () => {
        const insights = this.props.insights || []
        const newestInsight = insights[0]
        if (newestInsight && !newestInsight.seen) {
            await insight.seen(newestInsight._id)
            this.props.onSeen && this.props.onSeen()
        }
    }

    render() {
        const insights = this.props.insights
        return (
            <div className="cotg-insights">
                {(insights || []).map(insight =>
                    <Insight
                        key={`${insight._id}_${insight.updatedAt}`}
                        insight={insight}
                        highlight={insight._id === this.highlightedInsightId}
                        ref={insight._id === this.highlightedInsightId ? this.highlightedInsightRef : undefined} />)}
                {insights && insights.length === 0 &&
                    <InsightsComingSoon />
                }
            </div>
        )
    }
}