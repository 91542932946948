import axios from 'axios'
import business from './business'

const backend = axios.create()

// X-Business-Id request interceptor
backend.interceptors.request.use((() => {
    const _business = business.current()
    return async config => {
        config.headers = config.headers || {}
        config.headers['X-Business-Id'] = (await _business).id
        return config
    }
})())

// Session expired response interceptor
backend.interceptors.response.use(response => response, error => {
    const { response: { status } = {} } = error
    if (status === 401) {
        window.location.href = '/'
    }
    return Promise.reject(error)
})

export default backend