import React, { Component } from 'react'
import { user, business } from '../api'
import { _ } from './common'

const context = React.createContext()

export class Provider extends Component {
    state = {
        ready: false
    }

    async componentDidMount() {
        _.loadingWhile(async () => {
            const userSession = {}
            const _user = await user.me()
            if (_user) {
                userSession.user = _user
                userSession.business = await business.current()
            }
            this.setState({ ready: true, userSession })
        })
    }

    render() {
        return (
            <context.Provider value={this.state.userSession}>
                {this.state.ready && this.props.children}
            </context.Provider>
        )
    }
}

export default Consumer => props => (
    <context.Consumer>
        {userSession => <Consumer {...props} userSession={userSession} />}
    </context.Consumer>
)