import React from 'react'
import './LackAccess.css'
import sorryImage from './sorry.svg'

export default function LackAccess() {
    return (
        <div className="cotg-user-lack-access">
            <img src={sorryImage} alt='Sorry' />
            <div className="title">
                Sorry...
            </div>
            <div className="description">
                You do not have permissions to view this page
            </div>
        </div>
    )
}