import React, { Component } from 'react'

const context = React.createContext()

export class Provider extends Component {
    state = {}

    async componentDidMount() {
        window.addEventListener("resize", this.onResize)
        this.onResize()
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.onResize)
    }

    onResize = () => {
        let type
        const sizeClasses = new Set()
        if (window.innerWidth <= 650) {
            type = 'mobile'
            sizeClasses.add('small')
            if (window.innerWidth <= 320 || window.innerHeight <= 600)
                sizeClasses.add('medium')
            if (window.innerWidth >= 425 || window.innerHeight >= 700)
                sizeClasses.add('large')

        } else {
            type = 'desktop'
            sizeClasses.add('small')
            if (window.innerWidth >= 800)
                sizeClasses.add('medium')
            if (window.innerWidth >= 1024)
                sizeClasses.add('large')
        }

        const extras = new Set()
        if (/iphone|ipad|ipod/i.test(window.navigator.userAgent))
            extras.add('ios')
        if (window.navigator.standalone)
            extras.add('standalone')


        const labels = new Set(
            [`cotg-device-${type}`]
                .concat([...sizeClasses].map(sizeClass => `cotg-device-${type}_${sizeClass}`))
                .concat([...sizeClasses].map(sizeClass => `cotg-device-${sizeClass}`))
                .concat([...extras].map(extra => `cotg-device-${extra}`)))

        this.setState({ type, sizeClasses, extras, labels })

    }

    render() {
        return (
            <context.Provider value={this.state}>
                {Object.keys(this.state).length > 0 && this.props.children}
            </context.Provider>
        )
    }
}

export default Consumer => props => (
    <context.Consumer>
        {deviceInfo => <Consumer {...props} deviceInfo={deviceInfo} />}
    </context.Consumer>
)