import React, { Component } from 'react'
import { _ } from '../common'
import { user } from '../../api'
import comoLogo from './como-logo.png'
import emailIcon from './email-icon.svg'
import passwordIcon from './password-icon.svg'
import './Login.css'

export default class Login extends Component {
    state = {
        mode: 'SIGN_IN',
        email: '',
        password: '',
        totp: '',
        processing: false,
        valid: false,
        error: false,
        resetEmailSent: false,
        get2FA: false,
        errorMessage: ""
    }

    buttonRef = React.createRef()

    componentDidUpdate(_, { valid: prevValid }) {
        const { email, password, mode } = this.state
        const valid = email && /\S+@\S+\.\S+/.test(email) && ((password && password.length) || mode === 'PASSWORD_RESET')
        if (valid !== prevValid)
            this.setState({ valid })
    }

    handleFieldChange = fieldName => event => this.setState({ [fieldName]: event.target.value })

    handleFieldKeyPress = event => {
        if (event.key === 'Enter')
            this.buttonRef.current.click()
    }

    submit = async () => _.loadingWhile(async () => {
        this.setState({ processing: true, error: false })
        const { mode, email, password, totp } = this.state

        if (mode === 'SIGN_IN') {
            const response = await user.login(email, password, totp);
            if(typeof response === "string") {

                let errorMessage = response;

                if(response.includes("blocked"))
                    errorMessage = "Maximum number of failed login attempts exceeded. Please contact Support to unlock your account";
                else if(response.includes("invalid_credentials"))
                    errorMessage = "Incorrect email or password";

                this.setState({ processing: false, error: true, errorMessage })

            }
            else if(response.get2FA) {
                this.setState({ processing: false, error: false, get2FA: true, errorMessage: ""})
            } else if(response.message) {
                console.error(response.error);
                this.setState({ processing: false, error: true, errorMessage: response.message })
            } else {
                this.setState({ processing: false, error: !response, errorMessage: "" })
            }
        }
        if (mode === 'PASSWORD_RESET') {
            await user.resetPassword(email)
            this.setState({ processing: false, resetEmailSent: true })
        }
    })

    changeMode = mode => () => this.setState({ mode, error: false, resetEmailSent: false })

    render() {
        const { valid, processing, error, mode, resetEmailSent, get2FA } = this.state
        return (
            <div className={_.classes('cotg-user-login', error && 'error')}>
                <div className="logo">
                    <img src={comoLogo} alt="Logo" />
                </div>
                {mode === 'SIGN_IN' &&
                    <React.Fragment>
                        <div className="title">sign in</div>
                        <div className="greeting">Hello there! Sign In and watch your live dashboard</div>
                        {this.state.errorMessage && <div className="error-Message"> {this.state.errorMessage} </div>}
                        {get2FA && <div className='greeting' style={{background: 'gray'}}>We sent a code to your email</div>}
                        <div className="inputs">
                            <div className="input">
                                <input
                                    placeholder="Email"
                                    value={this.state.email}
                                    onChange={this.handleFieldChange('email')}
                                    onKeyPress={this.handleFieldKeyPress} />
                                <img src={emailIcon} alt="Email" />
                            </div>
                            <div className="input">
                                <input
                                    placeholder="Password"
                                    type="password"
                                    value={this.state.password}
                                    onChange={this.handleFieldChange('password')}
                                    onKeyPress={this.handleFieldKeyPress} />
                                <img src={passwordIcon} alt="Password" />
                            </div>
                            { get2FA && <div className="input">
                                <input
                                    placeholder="2FA Code - Google Auth / Email"
                                    type="text"
                                    value={this.state.totp}
                                    onChange={this.handleFieldChange('totp')}
                                    onKeyPress={this.handleFieldKeyPress} />
                                <img src={passwordIcon} alt="2FA Code" />
                            </div>}
                        </div>
                        <div className="submit">
                            <button ref={this.buttonRef} disabled={!valid || processing} onClick={this.submit}>sign in</button>
                            <div className="hint" onClick={this.changeMode('PASSWORD_RESET')}>
                                Forgot password? <div className="bold">Reset</div>
                            </div>
                        </div>
                    </React.Fragment>
                }
                {mode === 'PASSWORD_RESET' &&
                    <React.Fragment>
                        <div className="title">reset password</div>
                        {!resetEmailSent &&
                            <div className="greeting">Enter your email to reset your password</div>
                        }
                        {resetEmailSent &&
                            <div className="greeting">Instructions were sent to your email</div>
                        }
                        <div className="inputs">
                            <div className="input">
                                <img src={emailIcon} alt="Email" />
                                <input
                                    placeholder="Email"
                                    value={this.state.email}
                                    onChange={this.handleFieldChange('email')}
                                    onKeyPress={this.handleFieldKeyPress}
                                    readOnly={resetEmailSent}
                                />
                            </div>
                        </div>
                        <div className="submit">
                            {!resetEmailSent &&
                                <button ref={this.buttonRef} disabled={!valid || processing} onClick={this.submit}>reset password</button>
                            }
                            <div className="hint" onClick={this.changeMode('SIGN_IN')}>
                                Back to <div className="bold">Sign In</div>
                            </div>
                        </div>
                    </React.Fragment>
                }
            </div>
        )
    }
}
