import React, { Component } from 'react'
import { businessKPIs } from '../../api'
import { _, Select } from '../common'
import AmountKPI from './AmountKPI'
import GroupByKPI from './GroupByKPI'
import OverTimeKPI from './OverTimeKPI'
import salesIcon from './kpi-sales-icon.svg'
import withUserSession from '../WithUserSession'
import './Dashboard.css'

const PERIOD_POLL_INTERVAL = {
    'today': 5,
    'this_week': 10,
    'this_month': 15
}

class Dashboard extends Component {
    state = {
        liveUUID: null,
        period: null,
        kpis: {}
    }

    liveTimer = null

    async componentDidMount() {
        this.setState({ period: sessionStorage.getItem('como_otg_period') || 'today' })
        const pollForKPIs = (() => {
            let lastPoll = Date.now()
            return async () => {
                const live = this.props.live
                const { period, liveUUID } = this.state
                const shouldPoll = ((Date.now() - lastPoll) / 1000) >= PERIOD_POLL_INTERVAL[period] && live && liveUUID
                if (shouldPoll) {
                    await Promise.all(this.updateKPIs(period, state => this.liveTimer && (!state || state.liveUUID === liveUUID)))
                    lastPoll = Date.now()
                }
                this.liveTimer && setTimeout(pollForKPIs, 1000)
            }

        })()
        this.liveTimer = setTimeout(pollForKPIs, 1000)
    }

    componentWillUnmount() {
        clearInterval(this.liveTimer)
        this.liveTimer = null
    }

    async componentDidUpdate(prevProps, prevState) {
        const { period } = this.state
        if (period !== prevState.period) {
            sessionStorage.setItem('como_otg_period', period)
            const liveUUID = _.uuid()
            this.setState({ kpis: {}, liveUUID, loading: true })
            _.startLoading()
            const updateKPIsPromises = this.updateKPIs(period, state => this.liveTimer && (!state || state.liveUUID === liveUUID))
            await Promise.race(updateKPIsPromises)
            _.stopLoading()
            await Promise.all(updateKPIsPromises)
            if (this.liveTimer)
                this.setState({ loading: false })
        }
    }

    updateKPIs = (period, updatePredicate = (() => true)) => {
        const updateKPI = key => kpi => {
            if (!updatePredicate())
                return
            this.setState(state => {
                if (!updatePredicate(state))
                    return
                const kpis = Object.assign({}, state.kpis)
                kpis[key] = kpi
                return { kpis }
            })
        }
        return [
            businessKPIs.getBuyingMembers(period).then(updateKPI('buyingMembers')),
            businessKPIs.getNewMembers(period).then(updateKPI('newMembers')),
            businessKPIs.getSales(period).then(updateKPI('sales')),
            businessKPIs.getSalesByLocation(period).then(updateKPI('salesByLocation')),
            businessKPIs.getSalesOverTime(period).then(updateKPI('salesOverTime')),
            businessKPIs.getVisits(period).then(updateKPI('visits')),
            businessKPIs.getVisitAvgSpend(period).then(updateKPI('visitAvgSpend')),
            businessKPIs.getAppOpens(period).then(updateKPI('appOpens')),
            businessKPIs.getRedeems(period).then(updateKPI('redeems')),
        ]
    }

    updateFilter = name => event => this.setState({ [name]: event.target.value })

    updatePeriod = period => this.setState({ period })

    toggleLive = () => this.setState(({ live }) => ({ 'live': !live }))

    render() {
        const { period, kpis, liveUUID, loading } = this.state
        const currencySymbol = this.props.userSession.business.currencySymbol
        return (
            <div className="cotg-dashboard">
                {period &&
                    <Select
                        value={period}
                        onChange={this.updatePeriod}
                        disabled={loading}>
                        <Select.Option value="today">Today</Select.Option>
                        <Select.Option value="this_week">This Week</Select.Option>
                        <Select.Option value="this_month">This Month</Select.Option>
                    </Select>
                }
                <div key={liveUUID} className="kpis">
                    <AmountKPI title="sales" data={kpis.sales} valuePrefix={currencySymbol} icon={salesIcon} span />
                    <OverTimeKPI data={kpis.salesOverTime} period={period} minSize={2} keys={['memberValue', 'nonMemberValue']} displayKeys={['Members', 'Non-Members']} valuePrefix={currencySymbol} />
                    <AmountKPI title="new members" data={kpis.newMembers} />
                    <AmountKPI title="buying members" data={kpis.buyingMembers} />
                    <AmountKPI title="redeems" data={kpis.redeems} />
                    <AmountKPI title="visits" data={kpis.visits} />
                    <GroupByKPI title="top locations" data={kpis.salesByLocation} minSize={2} groupBy="locationName" valuePrefix={currencySymbol} />
                    <AmountKPI title="app opens" data={kpis.appOpens} />
                    <AmountKPI title="visit avg spend" data={kpis.visitAvgSpend} valuePrefix={currencySymbol} />
                </div>
            </div>
        )
    }
}

export default withUserSession(Dashboard)